import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Pressematerial from "../components/frontpage/pressmaterial"
import TeacherMaterial from "../components/frontpage/teachermaterial"
import Downloads from "../components/frontpage/download"
import Contact from "../components/frontpage/contact"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"
import styled from "styled-components"

const IndexPage = () => {
  const query = useStaticQuery(graphql`
    query {
      background3: file(relativePath: { eq: "bigdiv-3-bg.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      appIcon: file(relativePath: { eq: "bronzeon-icon.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      portrait: file(relativePath: { eq: "philipp_stockhammer.png" }) {
        childImageSharp {
          fixed(quality: 100, width: 140) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      appDesMonats: file(relativePath: { eq: "App_des_Monats.png" }) {
        childImageSharp {
          fixed(quality: 99, width: 200) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <Seo   lang="de" title="Startseite" />
      <Content className="content">
        <HeaderVideo id="header-video">
          <iframe
            title="Bronzeon Trailer"
            src="https://www.youtube-nocookie.com/embed/ExDhpgS3LhY?rel=0&amp;controls=0&amp;showinfo=0"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>

        </HeaderVideo>
        <div id="bestGame-btn">
          <a href="#review">
            <Img
                  fixed={query.appDesMonats.childImageSharp.fixed}
                  alt="App des Monats"
            />
          </a>
        </div>
        <Downloads />

        <BackgroundImage
          Tag="div"
          className="download-secion"
          fluid={query.background3.childImageSharp.fluid}
        >

          <div id={"informations_and_downloads"} className="container-fluid">
            <h2>Informationen &amp; Downloads</h2>
            <div className="flex-2">
              <TeacherMaterial />
              <Pressematerial />
            </div>
          </div>
        </BackgroundImage>

        <div id="the_story_behind">
          <div className="container">
            <h2>Die Geschichte dahinter</h2>
            <p>
              Die Bronzezeit umfasst in Mitteleuropa etwa den Zeitraum von 2200
              bis 800 v. Chr..
              <br />
              Bronze ist eine Legierung, bestehend aus 90 % Kupfer und 10 %
              Zinn, Es war mit der Bronze erstmals möglich, Reichtum anzuhäufen,
              welcher auch leicht transportabel war. Bronzebarren wurden als
              Zahlungsmittel eingesetzt. Die Entstehung von stark befestigten
              Siedlungen und die Erfindung des Schwertes werden oftmals als
              Hinweis auf eine Zunahme kriegerischer Auseinandersetzungen
              gedeutet.
              <br />
              <br />
              Basierend auf neuesten wissenschaftlichen Erkenntnissen von Prof.
              Dr. Philipp W. Stockhammer können Schüler hier das Leben im
              Bronzezeitalter spielerisch erlernen. Dabei gilt es eine Siedlung
              über mehrere Generationen hinweg aufzubauen, zu verwalten und ihr
              Überleben zu sichern.
            </p>
          </div>
        </div>
        <div id="about-philipp">
          <div className="container">
            <Img
              fixed={query.portrait.childImageSharp.fixed}
              alt="Philipp Stockhammer"
            />
            <p>
              <b>Prof. Dr. Philipp W. Stockhammer</b> ist Initiator und Autor
              von <b className="bronzeon">BRONZEON</b>.<br />
              Er lehrt am Institut für vor- und frühgeschichtliche Archäologie
              an der Ludwig-Maximlians-Univer­sität München.
              <br />
              Seine wissenschaftlichen Erkenntnisse über die Bronzezeit haben
              weltweit Aufsehen erregt. 
            </p>
            <p>
              <a
                href="mailto:philipp.stockhammer@lmu.de"
                className="buttontype1"
              >
                {"Ich habe weitere Fragen an Philipp Stockhammer"}
              </a>
            </p>
          </div>
        </div>
        <div id="review">
          <div class="container">
            <h2>
              Review
            </h2>
            <h4 class="no-margin-bottom">
              <b>
              BRONZEON ist App des Monats Oktober 2023
              </b>
            </h4>
            <p>Die Deutsche Akademie für Kinder- und Jugendliteratur zeichnet monatlich eine App, ein Game oder ein digitales Angebot mit Kinder- bzw. Jugendliteratur- oder -kulturbezug aus. Für Oktober 2023 empfiehlt die Jury die App Bronzeon.</p> 
            <a href="https://www.akademie-kjl.de/buch-app-empfehlungen/app-des-monats/" target="__blank" class="buttontype1">
              Zur Auszeichnung</a>
            
  
            <h4 class="no-margin-bottom">
              <b>BRONZEON reviewed as one of the 'Best games like civilisation'</b> 
            </h4>
            <p>
            Neueste wissenschaftliche Entdeckungen wurden ein Spiel, in dem du eine Familie von der Steinzeit bis zur Bronzezeit führst. Sammele Nahrung, mache Fortschritte und sehe ob du dieses Zeitalter überlebt hättest.
            </p>
            <p>
              <a href="https://freeappsforme.com/games-like-civilization/#bronzeon" target="__blank" class="buttontype1"
                >Zur Review</a>
            </p>
          </div>
        </div>

        <Contact />
      </Content>
    </Layout>
  )
}

export default IndexPage

const Content = styled.div`
  display: flex;
  flex-direction: column;

  * {
    text-align: center;
    color: #fff;
  }
`

const HeaderVideo = styled.div`
  background-color: #71432d;

  iframe {
    width: 100%;
    height: calc(100vw * (3 / 4));
  }

  @media (min-width: 768px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;

    iframe {
      height: 100vh;
    }
  }
`
