import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Sponsors from "../sponsors"

const Contact = () => {
  const imageQuery = useStaticQuery(graphql`
    query {
      backgroundImage: file(relativePath: { eq: "bigdiv-2-bg.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <div id={"contact"}>
      <BackgroundImage
        className="min-30vh-div bigdiv2 contact"
        fluid={imageQuery.backgroundImage.childImageSharp.fluid}
      >
        <div className="container">
          <br />
          <h2>Kontakt</h2>
          <br />
          <p>
            <b>Univ.-Prof. Dr. Philipp W. Stockhammer</b>
            <br />
            Professur für Prähistorische Archäologie (Schwerpunkt
            Ostmittelmeerraum)
            <br />
            Institut für Vor- und Frühgeschichtliche Archäologie und
            Provinzialrömische Archäologie
            <br />
            Ludwig-Maximilians-Universität München
            <br />
            Schellingstraße 12, 80799 München, Germany |&nbsp;
            <a href="mailto:philipp.stockhammer@lmu.de">
              {"E-Mail an Philipp Stockhammer"}
            </a>
          </p>
          <Sponsors />
        </div>
      </BackgroundImage>
    </div>
  )
}

export default Contact
