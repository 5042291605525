import React from "react"

import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

const TeacherMaterial = () => {
  const images = useStaticQuery(graphql`
    query {
      wheat: file(relativePath: { eq: "gameicon_1.png" }) {
        childImageSharp {
          fixed(quality: 100, height: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      background: file(relativePath: { eq: "parchment-bg-1.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 850) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <BackgroundImage
      Tag={"div"}
      fluid={images.background.childImageSharp.fluid}
    >
      <div className="information-card">
        <Img fixed={images.wheat.childImageSharp.fixed} alt="Weizenaehre" />
        <h3>Begleitmaterial zum Unterricht</h3>
        <p>
          <b className="bronzeon">BRONZEON</b> ist ein Lernspiel für den
          Geschichtsunterricht der sechsten Klasse. Es ist auf den Lehrplan Plus
          der Sekundarstufe eins für bayerische Gymnasien abgestimmt. Sie können{" "}
          <b className="bronzeon">BRONZEON</b> ganz einfach in Ihren Unterricht
          integrieren. Erfahren Sie alles dazu in der Infomappe für Lehrer
          (PDF). Wollen Sie an Ihrer Schule <b className="bronzeon">BRONZEON</b>{" "}
          bekannt machen? Dann können Sie hier auch Plakat und Flyer
          herunterladen.
        </p>
        <div className="button-row">
          <a
            href={
              "https://lernplattform.mebis.bayern.de/course/view.php?id=526342"
            }
            className="buttontype2"
            target="_blank"
            rel="noopener noreferrer"
          >
            {"Jetzt auf mebis"}
          </a>
        </div>
      </div>
    </BackgroundImage>
  )
}

export default TeacherMaterial
