import React from "react"

import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import styled from "styled-components"

const Downloads = () => {
  const imageQuery = useStaticQuery(graphql`
    query {
      appIcon: file(relativePath: { eq: "bronzeon-icon.png" }) {
        childImageSharp {
          fixed(quality: 100, width: 290) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      backgroundImage: file(relativePath: { eq: "bigdiv-2-bg.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      googlePlayImage: file(relativePath: { eq: "google_play.png" }) {
        id
        childImageSharp {
          fixed(quality: 100, height: 70) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      appStoreImage: file(relativePath: { eq: "apple_app_store.png" }) {
        childImageSharp {
          fixed(quality: 100, height: 70) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  return (
    <ContentArea>
      <BackgroundImage
        Tag="div"
        fluid={imageQuery.backgroundImage.childImageSharp.fluid}
      >
        <Container id={"game_download"}>
          <LogoContainer>
            <Img
              fixed={imageQuery.appIcon.childImageSharp.fixed}
              alt="bronzeon logo"
            />
          </LogoContainer>
          <Headline>Geschichte selbst erleben</Headline>
          <p>
            Schaffst Du es, in der Bronzezeit zu überleben? Baue eine Siedlung
            auf – und halte sie über mehrere Generationen hin aufrecht! Bronzeon
            gibt’s für Smartphone und Tablet.
          </p>
          <SubHeadline>Jetzt kostenlos downloaden</SubHeadline>
          <DownloadButtons>
            <a
              href="https://apps.apple.com/us/app/bronzeon/id1475217700"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Img
                fixed={imageQuery.appStoreImage.childImageSharp.fixed}
                alt="Apple App Store"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.MilkroomStudiosGmbH.Bronzeon"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Img
                fixed={imageQuery.googlePlayImage.childImageSharp.fixed}
                alt="Goole Play"
              />
            </a>
          </DownloadButtons>
        </Container>
      </BackgroundImage>
    </ContentArea>
  )
}

export default Downloads

const ContentArea = styled.div`
  @media (min-width: 768px) {
    margin-top: 100vh;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-left: auto;
  margin-right: auto;
  padding: 95px 15px;
  min-height: 100vh;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
  }

  * {
    font-size: 16px;
    word-wrap: break-word;
  }
`

const LogoContainer = styled.div`
  width: 290px;
  max-width: 100%;
  height: auto;
`

const Headline = styled.h1`
  margin-top: 56px;
  margin-bottom: 34px;
  font-size: 60px;
  font-family: "Norse", "Arial", "Helvatica", sans-serif;
  @media (max-width: 500px) {
    font-size: 45px;
  }
`

const SubHeadline = styled.h2`
  margin-top: 44px;
  margin-bottom: 34px;
  font-size: 40px;
  font-family: "Norse", "Arial", "Helvatica", sans-serif;
  @media (max-width: 500px) {
    font-size: 25px;
  }
`

const DownloadButtons = styled.div`
  margin-top: 24px;

  a ~ a {
    @media (min-width: 768px) {
      margin-left: 60px;
    }
  }
`
