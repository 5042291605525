import React from "react"

import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

const PressMaterial = () => {
  const images = useStaticQuery(graphql`
    query {
      coins: file(relativePath: { eq: "gameicon_2.png" }) {
        childImageSharp {
          fixed(quality: 100, height: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      background: file(relativePath: { eq: "parchment-bg-1.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 850) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <BackgroundImage
      Tag={"div"}
      fluid={images.background.childImageSharp.fluid}
    >
      <div className="information-card">
        <Img fixed={images.coins.childImageSharp.fixed} alt="Muenzen" />
        <h3>Pressematerial</h3>
        <p>
          Sie wollen über <b className="bronzeon">BRONZEON</b> berichten? Hier
          finden Sie folgende Downloads für Ihre Berichterstattung.
        </p>
        <div className="button-row">
          <a
            className="buttontype2"
            href={require("../../images/bronzeon-icon.png")}
            target="_blank"
            rel="noopener noreferrer"
          >
            Download Logo
          </a>
          <a
            href={require("../../documents/plakat.pdf")}
            className="buttontype2"
            target="_blank"
            rel="noopener noreferrer"
          >
            {"Download Plakat"}
          </a>
          <a
            className="buttontype2"
            href={require("../../documents/flyer.pdf")}
            target="_blank"
            rel="noopener noreferrer"
          >
            {"Download Flyer"}
          </a>
        </div>
      </div>
    </BackgroundImage>
  )
}

export default PressMaterial
